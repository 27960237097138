.App{
  text-align: center;
  background-color: #FFFFFF;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: large;
  color: #333333;
  max-width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFFFFF;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: #999999 1px solid;
  position: absolute;
  height: 80px; /* Asegúrate de establecer una altura para el header */
}

.App-header-left {
  padding-left: 30px;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-header-left img {
  margin-right: 10px;
}

.App-header-right a {
  color: #333333;
  margin-right: 30px;
  text-decoration: none;
}

.App-header-right a:hover {
  color: #555555;
}

.App-header-right {
  padding-right: 30px;
  max-width: 50%;
}

button{
  transition: 0.3s;
  font-size: medium;
  background-color: transparent;
  border: #333333 1px solid;
  border-radius: 4px;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

button:hover{
  transition: 0.3s;
  background-color: #333333;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  color: #f5f5f5;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-slider {
  margin-top: 80px; /* Debe ser al menos igual a la altura del header */
  width: 100%;
  display: block;
  background-color: #F3F4F6;
  box-sizing: border-box;
  padding: 50px;
  padding-bottom: 80px;
  border-bottom: #999999 1px solid;
}

.App-slider p {
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}

h2 {
  font-size: 2.2em;
}

.App-frame {
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 50px;
  padding-bottom: 80px;
  border-bottom: #999999 1px solid;
}

.dark{ background-color: #F3F4F6; }
.light{ background-color: #FFFFFF; }

.App-frame-projects {
  width: 100%;
  display: flex; /* Cambiado de block a flex */
  justify-content: center; /* Añadido para centrar los elementos */
  flex-wrap: wrap; /* Añadido para permitir que los elementos se envuelvan a la siguiente línea si no hay suficiente espacio */
  box-sizing: border-box;
  padding: 50px;
  padding-bottom: 80px;
  border-bottom: #999999 1px solid;
}

.App-frame-projects h2 {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.App-frame-projects txt {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.App-project {
  border: 1px solid #999999;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  max-width: fit-content;
  border-radius: 4px;
  padding: 10px;
  align-items: center;
  margin: 10px; /* Añadido para dar espacio entre las tarjetas */
  height: 450px;
  width: 400px;
}

.App-project img {
  height: 250px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #333333;
}

.App-skill {
  background-color: #333333;
  color: #f5f5f5;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  max-width: fit-content;
  border-radius: 20px;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  margin: 10px; /* Añadido para dar espacio entre las tarjetas */
}
